<template>
  <div class="inner-section">
    <card>
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">
          {{ $t('elearning_tpm.course_name') }}
          <slot v-if="$route.query.course_module_id">
            {{ $t('globalTrans.update') }}
          </slot>
          <slot v-else>
            {{ $t('globalTrans.entry') }}
          </slot>
        </h4>
      </template>
      <template v-slot:searchHeaderAction>
        <router-link to="course-managment" :class="'btn btn-add'"><i class="ri-arrow-left-line"></i>{{ $t('elearning_tpm.course_name') }} {{ $t('globalTrans.list') }}</router-link>
      </template>
      <template v-slot:searchBody>
      <b-overlay :show="loading">
        <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
          <b-form class="price-entry" id="form" @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
            <!-- <b-overlay :show="loadingCourseModule"> -->
              <b-row>
                <b-col lg="6" sm="6">
                  <ValidationProvider name="Organization"  vid="org_id" rules="required|min_value:1">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="org_id"
                        slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{$t('globalTrans.organization')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        v-model="formData.org_id"
                        :options="orgList"
                        id="org_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col lg="6" sm="6">
                  <ValidationProvider name="Training Type"  vid="training_type_id" rules="required|min_value:1">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="training_type_id"
                        slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{$t('elearning_config.training_type')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        v-model="formData.training_type_id"
                        :options="trainingTypeList"
                        id="training_type_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col lg="6" sm="6">
                  <ValidationProvider name="Training Title" vid="training_title_id" rules="required|min_value:1">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="training_title_id"
                      slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{$t('elearning_config.training_title')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        v-model="formData.training_title_id"
                        :options="trainingTitleList"
                        id="training_title_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col lg="6" sm="6">
                  <ValidationProvider name="Lesson Module"  vid="course_module_id">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="course_module_id"
                        slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{$t('elearning_tpm.course_module')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        v-model="formData.course_module_id"
                        :options="courseModuleList"
                        id="course_module_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                      <small v-if="courseError" class="text-danger">
                        {{courseError}}
                      </small>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
              </b-row>
            <!-- </b-overlay> -->
            <body-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('elearning_tpm.course_name') }}</h4>
              </template>
              <template v-slot:body>
                <b-overlay :show="loadingCourseModule">
                  <b-table-simple class="mb-0" hover small caption-top responsive bordered>
                    <b-tr style="background: #f9f9f9">
                      <th style="width:22%">{{$t('elearning_tpm.course_name_en')}} <span class="text-danger">*</span></th>
                      <th style="width:22%">{{$t('elearning_tpm.course_name_bn')}} <span class="text-danger">*</span></th>
                      <th style="width:25%">{{$t('elearning_tpm.course_details_en')}}</th>
                      <th style="width:25%">{{$t('elearning_tpm.course_details_bn')}}</th>
                      <th style="width:6%">{{ $t('globalTrans.action') }}</th>
                    </b-tr>
                    <b-tr v-for="(lesson,index) in formData.lessons" :key="index">
                      <b-td>
                        <keep-alive>
                          <ValidationProvider :vid="'course_name' + index" name="Course name (En)">
                            <div slot-scope="{ valid, errors }">
                              <b-form-input
                                rows="2"
                                v-model="lesson.course_name"
                                :name="'lessons['+index+'][course_name]'"
                                :id="'lessons['+index+'][course_name]'"
                                :placeholder="$t('elearning_tpm.course_name_en')"
                                :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="error invalid-feedback" role="alert">
                                {{ errors[0] }}
                              </div>
                              <small v-if="course_module_errors && course_module_errors['lessons.' + index + '.course_name']" class="text-danger">
                                {{ course_module_errors['lessons.'+index+'.course_name'] }}
                              </small>
                            </div>
                          </ValidationProvider>
                        </keep-alive>
                      </b-td>
                      <b-td>
                        <keep-alive>
                          <ValidationProvider :vid="'course_name_bn' + index"  name="Course name (Bn)">
                            <div slot-scope="{ valid, errors }">
                              <b-form-input
                                rows="2"
                                v-model="lesson.course_name_bn"
                                :name="'lessons['+index+'][course_name_bn]'"
                                :id="'lessons['+index+'][course_name_bn]'"
                                :placeholder="$t('elearning_tpm.course_name_bn')"
                                :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="error invalid-feedback" role="alert">
                                {{ errors[0] }}
                              </div>
                              <small v-if="course_module_errors && course_module_errors['lessons.' + index + '.course_name_bn']" class="text-danger">
                                {{ course_module_errors['lessons.'+index+'.course_name_bn'] }}
                              </small>
                            </div>
                          </ValidationProvider>
                        </keep-alive>
                      </b-td>
                      <b-td>
                        <keep-alive>
                          <ValidationProvider :vid="'course_details' + index" name="Course Details (En)">
                            <div slot-scope="{ valid, errors }">
                              <b-form-textarea
                                rows="2"
                                v-model="lesson.course_details"
                                :name="'lessons['+index+'][course_details]'"
                                :id="'lessons['+index+'][course_details]'"
                                :placeholder="$t('elearning_tpm.course_details_en')"
                                :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-textarea>
                              <div class="error invalid-feedback" role="alert">
                                {{ errors[0] }}
                              </div>
                              <small v-if="course_module_errors && course_module_errors['lessons.' + index + '.course_details']" class="text-danger">
                                {{ course_module_errors['lessons.'+index+'.course_details'] }}
                              </small>
                            </div>
                          </ValidationProvider>
                        </keep-alive>
                      </b-td>
                      <b-td>
                        <keep-alive>
                          <ValidationProvider :vid="'course_details_bn' + index" name="Course Details (Bn)">
                            <div slot-scope="{ valid, errors }">
                              <b-form-textarea
                                rows="2"
                                v-model="lesson.course_details_bn"
                                :name="'lessons['+index+'][course_details_bn]'"
                                :id="'lessons['+index+'][course_details_bn]'"
                                :placeholder="$t('elearning_tpm.course_details_bn')"
                                :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-textarea>
                              <div class="error invalid-feedback" role="alert">
                                {{ errors[0] }}
                              </div>
                              <small v-if="course_module_errors && course_module_errors['lessons.' + index + '.name']" class="text-danger">
                                {{ course_module_errors['lessons.'+index+'.name'] }}
                              </small>
                            </div>
                          </ValidationProvider>
                        </keep-alive>
                      </b-td>
                      <b-td>
                        <button @click="removeCourseModule(index, lesson)" class="btn btn-sm btn-danger float-right mt-4" type="button">
                          <i class="fas fa-window-close m-0"></i>
                        </button>
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td colspan="5">
                        <button
                          @click="addMore"
                          class="btn btn-sm btn-primary float-right"
                          type="button"
                        >{{$t('globalTrans.add_more')}}</button>
                      </b-td>
                    </b-tr>
                    <template v-if="formData.lessons.length === 0">
                      <b-tr>
                        <b-th colspan="5" class="text-center text-danger">{{$t('globalTrans.noDataFound')}}</b-th>
                      </b-tr>
                    </template>
                  </b-table-simple>
                </b-overlay>
              </template>
              <b-row>
                <div class="col-md-12">
                  <button
                    @click="addAll"
                    class="btn btn-sm btn-primary float-right"
                    type="button"
                  ><i class="fa fa-plus" aria-hidden="true"></i></button>
                </div>
              </b-row>
              </body-card>
              <body-card>
                <template v-slot:headerTitle>
                  <h4 class="card-title">{{$t('elearning_tpm.course_name')}} {{$t('globalTrans.list')}}</h4>
                </template>
                <template v-slot:body>
                  <b-row>
                    <div class="col-md-12">
                        <!-- <h4>{{$t('elearning_tpm.course_name')}} {{$t('globalTrans.list')}}</h4> -->
                        <b-table-simple class="mb-0" hover small caption-top responsive bordered>
                          <b-tr style="background: #f9f9f9">
                            <b-th style="width:17%">{{$t('elearning_tpm.course_module')}}</b-th>
                            <b-th style="width:17%">{{$t('elearning_tpm.course_name')}}</b-th>
                            <b-th style="width:20%">{{$t('elearning_tpm.course_details')}} </b-th>
                          </b-tr>
                          <b-tr v-for="(course_name,index) in formData.course_management_list" :key="index">
                            <b-td>{{ $i18n.locale === 'bn' ? course_name.module_name_bn : course_name.module_name }}</b-td>
                            <b-td>{{ $i18n.locale === 'bn' ? course_name.course_name_bn : course_name.course_name }}</b-td>
                            <b-td>{{ $i18n.locale === 'bn' ? course_name.course_details_bn : course_name.course_details }}</b-td>
                          </b-tr>
                          <template v-if="formData.course_management_list.length === 0">
                            <b-tr>
                              <b-th colspan="3" class="text-center text-danger pt-3 pb-3">{{$t('globalTrans.noDataFound')}}</b-th>
                            </b-tr>
                          </template>
                        </b-table-simple>
                    </div>
                  </b-row>
                  <div class="row">
                    <div class="col-sm-3"></div>
                    <div class="col text-right">
                      <br/>
                      <router-link to="course-managment" class="mr-1 btn btn-sm btn-danger">{{ $t('globalTrans.cancel') }}</router-link>
                      <b-button type="submit" variant="success" class="mr-2 btn-sm">{{ saveBtnName }}</b-button>
                    </div>
                  </div>
                </template>
              </body-card>
          </b-form>
        </ValidationObserver>
      </b-overlay>
      </template>
    </card>
  </div>
</template>
<script>

import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { courseManagmentStore, courseManagmentUpdate, courseManagmentShow, courseWiseModule } from '../../api/routes'
import flatpickr from 'flatpickr'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.$route.query.course_module_id) {
      const trTitle = this.$store.state.TrainingElearning.commonObj.courseModuleList.find(item => item.value === parseInt(this.$route.query.course_module_id))
      if (typeof trTitle !== 'undefined') {
        this.formData.training_type_id = trTitle.training_type_id
        this.formData.training_title_id = trTitle.training_title_id
        this.formData.org_id = trTitle.org_id
        this.formData.course_module_id = this.$route.query.course_module_id
      }
      // this.getFormData()
    }
  },
  mounted () {
    flatpickr('.fromDate', {})
  },
  data () {
    return {
      loading: false,
      loadingCourseModule: false,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      formData: {
        id: '',
        org_id: 0,
        training_type_id: 0,
        training_title_id: 0,
        course_module_id: 0,
        lessons: [
          {
            id: '',
            course_name: null,
            course_name_bn: null,
            course_details: null,
            course_details_bn: null
          }
        ],
        course_management_list: [],
        lessons_remove: []
      },
      officeTypeList: [],
      trainers: [],
      officeList: [],
      courseModuleList: [],
      trainerListData: [],
      trainingTypeList: [],
      course_module_errors: [],
      circularList: [],
      circularErrorMsg: false,
      courseError: '',
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      trainingTitleList: []
    }
  },
  computed: {
    venus: function () {
      return this.$store.state.TrainingElearning.commonObj.venus.filter(item => item.status === 1)
    },
    trainingQuarterSetupList: function () {
      return this.$store.state.TrainingElearning.commonObj.trainingQuarterSetupList.filter(item => item.status === 1)
    },
    orgList: function () {
      return this.$store.state.CommonService.commonObj.componentOrgList.filter(item => item.status === 1)
    },
    fiscalYearList: function () {
      return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 1)
    }
  },
  watch: {
    'formData.org_id': function (newValue) {
      this.trainingTypeList = this.getTypeList(newValue)
    },
    'formData.training_type_id': function (newValue) {
      this.trainingTitleList = this.getTrainingTitleList(newValue)
    },
    'formData.training_title_id': function (newValue) {
      this.courseModuleList = this.getcourseModuleList(newValue)
    },
    'formData.course_module_id': function (newValue) {
      if (newValue) {
        this.courseWiseModule(newValue)
      }
    }
  },
  methods: {
    removeCourseModule (index, data) {
      this.formData.lessons.splice(index, 1)
      this.formData.lessons_remove.push(data)
    },
    addAll () {
      const courseModuels = this.formData.course_management_list.find(course => course.course_module_id === parseInt(this.formData.course_module_id))
      if (typeof courseModuels === 'undefined') {
        this.formData.lessons.map(item => {
          if (this.formData.course_module_id && item.course_name && item.course_name_bn) {
            const modules = {}
            const courseObj = this.$store.state.TrainingElearning.commonObj.courseModuleList.find(doc => doc.value === parseInt(this.formData.course_module_id))
            if (typeof courseObj !== 'undefined') {
              modules.module_name = courseObj.text_en
              modules.module_name_bn = courseObj.text_bn
            } else {
              modules.module_name = ''
              modules.module_name_bn = ''
            }
            modules.course_name = item.course_name
            modules.course_name_bn = item.course_name_bn
            modules.course_details = item.course_details
            modules.course_details_bn = item.course_details_bn
            modules.course_module_id = this.formData.course_module_id
            modules.org_id = this.formData.org_id
            modules.training_type_id = this.formData.training_type_id
            modules.training_title_id = this.formData.training_title_id
            modules.id = item.id
            this.formData.course_management_list.push(modules)
          }
        })
        this.courseModuleListReset()
        this.formData.course_module_id = 0
      } else {
        this.courseError = 'This Course is allready assigned'
        return false
      }
    },
    addMore () {
      this.formData.lessons.push({
        id: '',
        course_name: null,
        course_name_bn: null,
        course_details: null,
        course_details_bn: null
      })
    },
    async courseWiseModule () {
      this.loadingCourseModule = true
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, courseWiseModule + '/' + this.formData.course_module_id)
      if (!result.success) {
        this.courseModuleListReset()
      } else {
        if (result.data.length > 0) {
          this.formData.lessons = result.data
        } else {
          this.courseModuleListReset()
        }
        this.formData.lessons_remove = []
      }
      this.loadingCourseModule = false
    },
    courseModuleListReset () {
      this.formData.lessons = [
        {
          id: '',
          course_name: null,
          course_name_bn: null,
          course_details: null,
          course_details_bn: null
        }
      ]
      this.formData.lessons_remove = []
    },
    async getFormData () {
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, courseManagmentShow + '/' + this.$route.query.id)
      if (!result.success) {
        this.formData = []
      } else {
        this.formData = result.data
        this.formData.lessons = result.data.lessons
        this.formData.lessons_remove = []
      }
    },
    getTypeList (typeId) {
       const trainingTypeList = this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
       if (typeId) {
         return trainingTypeList.filter(item => item.org_id === typeId)
       }
       return trainingTypeList
    },
    getTrainingTitleList (categoryId) {
      const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
      if (categoryId) {
        return trainingTitleList.filter(item => item.training_type_id === categoryId)
      }
      return trainingTitleList
    },
    onChangeFile (e) {
      this.formData.upload_guidelines = e.target.files[0]
    },
    getcourseModuleList (trainingTitleId) {
      const courseModuleList = this.$store.state.TrainingElearning.commonObj.courseModuleList
      if (trainingTitleId) {
        return courseModuleList.filter(item => item.training_title_id === trainingTitleId)
      }
      return courseModuleList
    },
    async createData () {
      this.loading = true
      let result = null
      if (this.formData.id) {
        result = await RestApi.postData(trainingElearningServiceBaseUrl, `${courseManagmentUpdate}/${this.formData.id}`, this.formData)
      } else {
        result = await RestApi.postData(trainingElearningServiceBaseUrl, courseManagmentStore, this.formData)
      }
      this.loading = false
      if (result.success) {
        this.$store.dispatch('TrainingElearning/mutateTrainingElearningCommonProperties', { hasDropdownLoaded: false })
        this.$toast.success({
            title: 'Success',
            message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
            color: '#D6E09B'
        })
        this.$router.push('/training-e-learning-service/tpm/course-managment')
      } else {
        this.course_module_errors = result.errors
        this.$refs.form.setErrors(result.errors)
        // this.$refs.form.setErrors(result.errors)
      }
  }
  }
}
</script>
